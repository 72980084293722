import vault from "../assets/img/vault.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import management from "../assets/img/management.svg";
import device from "../assets/img/device.svg";
import result from "../assets/img/result.svg";
import map from "../assets/img/map.svg";
import lot from "../assets/img/lot.svg";
import mobile from "../assets/img/mobile.svg";
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Recursos</h2>
                        <p>Algumas de nossas principais funcionalidades.<br></br> Estamos sempre atualizando nossos sistemas com novos recursos.</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={management} alt="Image" />
                                <h5>Gestão de Banca</h5>
                            </div>
                            <div className="item">
                                <img src={lot} alt="Image" />
                                <h5>Gestão de Loterias</h5>
                            </div>
                            <div className="item">
                                <img src={device} alt="Image" />
                                <h5>Gestão de Dispositivo</h5>
                            </div>
                            <div className="item">
                                <img src={result} alt="Image" />
                                <h5>Resultados Automáticos</h5>
                            </div>
                            <div className="item">
                                <img src={vault} alt="Image" />
                                <h5>Gestão de Caixa</h5>
                            </div>
                            <div className="item">
                                <img src={map} alt="Image" />
                                <h5>Mapa de Apostas</h5>
                            </div>
                            <div className="item">
                                <img src={mobile} alt="Image" />
                                <h5>Aplicativo Mobile</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
