import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-us.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { Plans } from "./Plans";

export const Contact = () => {
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  }
  const webhook = 'https://discord.com/api/webhooks/1056285752113242245/YP1QKIopEFCwrwKSg31b3CNszR60Ljoi5rxTij6Be9RT_GmCG0RM4_-OOAVbwsTPSD1J';
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Enviar');
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
      setFormDetails({
        ...formDetails,
        [category]: value
      })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Enviando...");

    const messageData = {
      embeds: [{
        color: 3447003,
        thumbnail: {
          url: 'https://www.quantumsistemas.app/favicon.png'
        },
        fields: [
          {
            name: 'Nome',
            value: formDetails.firstName,
            inline: true
          },
          {
            name: 'Sobrenome',
            value: formDetails.lastName,
            inline: true
          },
          {
            name: 'Email',
            value: formDetails.email
          },
          {
            name: 'Celular',
            value: formDetails.phone
          },
          {
            name: 'Mensagem',
            value: formDetails.message
          },
        ]
      }]
    }

    let response = await fetch(webhook, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(messageData),
    });

    console.log(response);

    setButtonText("Enviar");
    setFormDetails(formInitialDetails);

    if (response.status == 204) {
      setStatus({ succes: true, message: 'Mensagem enviada com sucesso. Em breve entraremos em contato!'});
    } else {
      setStatus({ succes: false, message: 'Ocorreu um erro ao enviar sua mensagem. Fale conosco através de nosso whatsapp!'});
    }
  };

  return (
    <section className="contact" id="contact-us">
      <Container>
        <Row className="align-items-center">
          <Plans />
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>Fale Conosco</h2>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" value={formDetails.firstName} placeholder="Nome" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" value={formDetails.lasttName} placeholder="Sobrenome" onChange={(e) => onFormUpdate('lastName', e.target.value)}/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="email" value={formDetails.email} placeholder="Email" onChange={(e) => onFormUpdate('email', e.target.value)} />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="tel" value={formDetails.phone} placeholder="Celular" onChange={(e) => onFormUpdate('phone', e.target.value)}/>
                    </Col>
                    <Col size={12} className="px-1">
                      <textarea rows="6" value={formDetails.message} placeholder="Mensagem" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                      <button type="submit"><span>{buttonText}</span></button>
                    </Col>
                    {
                      status.message &&
                      <Col>
                        <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                      </Col>
                    }
                  </Row>
                </form>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
