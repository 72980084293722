import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logo.svg";
import instagramIcon from "../assets/img/instagram.svg";

const year = new Date().getFullYear();

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><img src={instagramIcon} alt="Instagram" /></a>
            </div>
            <p>Quantum Sistemas © Copyright {year} - Todos os direitos reservados.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
