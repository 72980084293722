import { Col, Row } from "react-bootstrap";

export const Plans = () => {
  return (
      <div id="plans">
        <Col lg={12}>
          <div className="newsletter-bx wow slideInUp">
            <Row>
              <Col xs={12}>
                <h2 className="text-center mb-5">Nossos Planos</h2>
                <p className="text-center"><b>EM BREVE IREMOS DISPONIBILIZAR NOSSA TABELA DE PLANOS!</b></p>
              </Col>
            </Row>
          </div>
        </Col>
      </div>
  )
}
