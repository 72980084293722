import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import device from "../assets/img/sistema-device.png";
import caixa from "../assets/img/sistema-caixa.png";
import apuracao from "../assets/img/sistema-apuracao.png";
import descarrego from "../assets/img/sistema-descarrego.png";
import mapa from "../assets/img/sistema-mapa.png";
import dashboard from "../assets/img/sistema-dashboard.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Dashboard",
      description: "Aplicação Banca",
      imgUrl: dashboard,
    },
    {
      title: "Mapa de Apostas",
      description: "Aplicação Banca",
      imgUrl: mapa,
    },
    {
      title: "Gestão de Dispositivos",
      description: "Aplicação Banca",
      imgUrl: device,
    },
    {
      title: "Descarrego",
      description: "Aplicação Banca",
      imgUrl: descarrego,
    },
    {
      title: "Gestão de Caixa",
      description: "Aplicação Banca",
      imgUrl: caixa,
    },
    {
      title: "Gestão de Loterias",
      description: "Aplicação Banca",
      imgUrl: apuracao,
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Sistemas</h2>
                <p>Estamos trabalhando para termos novos sistemas em nosso catálogo.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">JB</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">BETs</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <p>No momento o sistema de BETS encontra-se em estágio de desenvolvimento. Previsão de lançarmos no primeiro semestre de 2023.</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
